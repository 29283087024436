import React from 'react'
import styled, { css } from 'styled-components'

import arrowRightImg from '../assets/arrow-right.svg'
import arrowRightPrimaryImg from '../assets/arrow-right-lightgreen.svg'
import { config } from '../config'

const sharedArrowWrapperStyles = css`
  display: inline-flex;
  align-items: center;
  text-decoration: none !important;

  ${(props) =>
    props.primary &&
    css`
      color: ${config.colors.primary};

      &:hover {
        color: ${config.colors.base};
      }
    `}
`

const StyledButton = styled.button`
  border: none;
  background: transparent;
  margin: 0;
  padding: 0;
  border-radius: none;
  all: unset;
  cursor: pointer;

  ${(props) =>
    props.arrow &&
    css`
      ${sharedArrowWrapperStyles}
    `}
`

const ArrowImg = styled.img`
  display: block;
  width: 32px;
  height: auto;
  transition: transform 0.2s ease;
  margin-right: 12px;
  position: relative;
  top: -1px;

  @media (min-width: ${config.breakpoints.tablet}) {
    width: 40px;
    margin-right: 17px;
  }

  ${(props) =>
    props.arrowLast &&
    css`
      order: 2;
      margin-left: 12px;

      @media (min-width: ${config.breakpoints.tablet}) {
        margin-left: 17px;
      }
    `}
`

export function Button({
  children,
  arrow,
  primary = false,
  arrowLast,
  ...other
}) {
  return (
    <StyledButton type="submit" arrow={arrow} primary={primary} {...other}>
      {arrow && (
        <ArrowImg src={primary ? arrowRightPrimaryImg : arrowRightImg} alt="" />
      )}
      <span>{children}</span>
    </StyledButton>
  )
}
