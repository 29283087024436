import React from 'react'
import styled from 'styled-components'

import { Link } from '../../link'
import { Catcher } from '../../catcher'
import { Preview } from '../../posts/archive/preview'
import { breakpoint } from '../../../config'
import { flexStyles, flexItemStyles, flexWidth } from '../../flex'

const Wrapper = styled.div`
  margin-bottom: 40px;

  @media ${breakpoint.tablet} {
    margin-bottom: 260px;
  }
`

const Flex = styled.div`
  ${flexStyles}
`

const Meta = styled.div`
  ${flexItemStyles}
  margin-bottom: 20px;

  @media ${breakpoint.tablet} {
    margin-bottom: 40px;
  }

  @media ${breakpoint.desktop} {
    ${flexWidth(2)}
  }
`

const Item = styled.div`
  ${flexItemStyles}
  margin-left: -20px;
  margin-right: -20px;

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  @media ${breakpoint.tablet} {
    margin-left: 0;
    margin-right: 0;
  }

  @media ${breakpoint.desktop} {
    ${flexWidth(4)}
    margin-bottom: 0 !important;
  }
`

const Action = styled.div`
  ${flexItemStyles}
  margin-top: 20px;

  @media ${breakpoint.tablet} {
    ${flexWidth(8)}
  }

  @media ${breakpoint.desktop} {
    ${flexWidth(10)}

    text-align: right;
  }
`

export function News({ news }) {
  return (
    <Wrapper>
      <Flex>
        <Meta>
          <Catcher>News</Catcher>
        </Meta>
        {news.map((item) => (
          <Item key={item.id}>
            <Preview post={item} />
          </Item>
        ))}
      </Flex>
      <Flex>
        <Action>
          <Link to="/news" arrow primary>
            Weitere News
          </Link>
        </Action>
      </Flex>
    </Wrapper>
  )
}
