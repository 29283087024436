import React from 'react'
import styled from 'styled-components'

import { config } from '../config'

const Wrapper = styled.div`
  background: ${config.colors.base};
  color: ${config.colors.primary};
  text-transform: uppercase;
  font-family: 'Morandi-Bold', sans-serif;
  display: inline-block;
  padding: 4px 8px 1px 8px;
  letter-spacing: 0.08em;
  font-size: 18px;

  @media (min-width: ${config.breakpoints.tablet}) {
    font-size: 32px;
    padding: 2px 10px 0 10px;
  }
`

export function Catcher({ children }) {
  return <Wrapper>{children}</Wrapper>
}
