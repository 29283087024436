import React from 'react'
import styled from 'styled-components'

import { Intro } from './intro'
import { News } from './news'
import { Container } from '../container'
import { Title } from '../title'
import { Text } from '../text'
import { Link } from '../link'
import { Flex } from '../flex'
import { Newsletter } from './newsletter'
import { breakpoint } from '../../config'

const Infos = styled.div`
  margin-bottom: 40px;

  @media ${breakpoint.tablet} {
    margin-bottom: 260px;
  }
`

export function Home({ news }) {
  return (
    <Container>
      <Intro />

      <News news={news} />

      <Title margin>Was ist Lokal+Fair?</Title>

      <Flex>
        <Flex.Item width={{ desktop: 8 }}>
          <Text preventBreakInside>
            <p>
              Lokal+Fair ist eine Initiative der Gemeinde Vaduz, der ersten Fair
              Trade Town in Liechtenstein. Die Auszeichnung Fair Trade Town wird
              von Swiss Fair Trade an Gemeinden und Städte verliehen, welche
              sich für den fairen Handel engagieren.
            </p>
            <p>
              Das Label wird von der Fair Trade Town Vaduz an lokale Gewerbe-
              und Gastronomiebetriebe vergeben, welche mindestens drei Fair
              Trade Produkte und ein Produkt aus dem Fürstentum Liechtenstein
              verwenden, nachhaltig handeln sowie beim Einkauf regionale
              Produkte bevorzugen.
            </p>
          </Text>
        </Flex.Item>
      </Flex>

      <br />

      <Infos>
        <Link to="/kriterien" arrow primary>
          Infos und Teilnahmebedingungen
        </Link>
      </Infos>

      <Newsletter />
    </Container>
  )
}
