import React from 'react'
import styled, { css } from 'styled-components'

import { Title } from '../title'
import desktopImg from './assets/FTT_KeyVisual_breit_Desktop.png'
import mobileImg from './assets/FTT_KeyVisual_schmal_iPhone.png'
import { config } from '../../config'

const Wrapper = styled.div`
  margin-bottom: 80px;

  @media (min-width: ${config.breakpoints.tablet}) {
    margin: -68px 0 250px 0;
  }
`

const Media = styled.div`
  margin: 52px -20px 34px -20px;

  @media (min-width: ${config.breakpoints.tablet}) {
    margin: 0 0 28px 0;
  }
`

const imgStyles = css`
  display: block;
  width: 100%;
  max-width: 100%;
  height: auto;
  mix-blend-mode: multiply;
`

const DesktopImg = styled.img`
  ${imgStyles}
  display: none;
  @media (min-width: ${config.breakpoints.tablet}) {
    display: block;
    width: 100%;
    height: auto;
  }
`

const MobileImg = styled.img`
  ${imgStyles}
  display: block;
  @media (min-width: ${config.breakpoints.tablet}) {
    display: none;
  }
`

const Subline = styled.div`
  margin-top: 0.75rem;

  @media (min-width: ${config.breakpoints.tablet}) {
    margin-top: 0.25rem;
  }
`

export function Intro() {
  return (
    <Wrapper>
      <Media>
        <DesktopImg
          src={desktopImg}
          alt="Lokal einkaufen. Fair handeln."
          width="1642"
          height="600"
        />
        <MobileImg src={mobileImg} alt="Lokal einkaufen. Fair handeln." />
      </Media>
      <Title>
        Lokal einkaufen.{' '}
        <span style={{ whiteSpace: 'nowrap' }}>Fair handeln.</span>
      </Title>
      <Subline>Eine Initiative der Gemeinde Vaduz</Subline>
    </Wrapper>
  )
}
