import React from 'react'
import styled, { css } from 'styled-components'

import { breakpoint } from '../../config'
import { Catcher } from '../catcher'
import { Box } from '../box'
import { Button } from '../button'
import { Input } from '../input'
import { flexStyles, flexItemStyles, flexWidth } from '../flex'

const Wrapper = styled.div`
  ${flexStyles}
`

const Meta = styled.div`
  ${flexItemStyles}
  margin-bottom: 20px;

  @media ${breakpoint.tablet} {
    margin-bottom: 40px;
  }

  @media ${breakpoint.desktop} {
    ${flexWidth(3)}
  }
`

const Main = styled.div`
  ${flexItemStyles}
  margin-left: -20px;
  margin-right: -20px;

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  @media ${breakpoint.tablet} {
    margin-left: 0;
    margin-right: 0;
  }

  @media ${breakpoint.desktop} {
    ${flexWidth(5)}
    margin-bottom: 0 !important;
  }
`

const Row = styled.div`
  margin-bottom: 1rem;

  ${(props) =>
    props.last &&
    css`
      margin-bottom: 1.5rem;
    `}

  display: flex;
  flex-wrap: wrap;
  align-items: center;

  > input {
    min-width: 220px;
    flex: 1;
  }
`

const Label = styled.label`
  width: 110px;
  display: block;

  @media (min-width: 860px) {
    width: 130px;
  }
`

export function Newsletter() {
  return (
    <Wrapper>
      <Meta>
        <Catcher>Newsletter</Catcher>
      </Meta>
      <Main>
        <Box>
          <h2>Bleiben Sie informiert!</h2> Haben Sie Interesse an unseren
          Aktionen und möchten Sie auf dem Laufenden bleiben? Abonnieren Sie
          gerne unseren Newsletter.
          <br />
          <br />
          <form
            action="https://erlebevaduz.us1.list-manage.com/subscribe/post?u=d989aea17b6c89e45cff6fb65&id=c3d7cce744"
            method="post"
            target="_blank"
            noValidate
          >
            <Row>
              <Label htmlFor="mce-EMAIL">E-Mail*:</Label>
              <Input type="email" name="EMAIL" id="mce-EMAIL" />
            </Row>
            <Row>
              <Label htmlFor="mce-FNAME">Vorname*:</Label>
              <Input type="text" name="FNAME" id="mce-FNAME" />
            </Row>
            <Row last>
              <Label htmlFor="mce-LNAME">Nachname*:</Label>
              <Input type="text" name="LNAME" id="mce-LNAME" />
            </Row>
            {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups*/}
            <div
              style={{ position: 'absolute', left: '-5000px' }}
              aria-hidden="true"
            >
              <input
                type="text"
                name="b_d989aea17b6c89e45cff6fb65_c3d7cce744"
                tabIndex={-1}
                defaultValue
              />
            </div>
            <Button type="submit" arrow primary name="subscribe">
              Anmelden
            </Button>
          </form>
        </Box>
      </Main>
    </Wrapper>
  )
}
