import React from 'react'
import { graphql } from 'gatsby'

import { Layout } from '../components/layout'
import { SEO } from '../components/seo'
import { Home } from '../components/home'

export default function IndexPage({ data }) {
  const news = data.allStrapiPost.edges.map(({ node }) => node)

  return (
    <Layout>
      <SEO title="Lokal + Fair" />
      <Home news={news} />
    </Layout>
  )
}

export const query = graphql`
  query GetHomeData {
    allStrapiPost(sort: { order: DESC, fields: datetime }, limit: 2) {
      edges {
        node {
          id
          title
          slug
          content
          datetime
          category {
            id
            title
          }
        }
      }
    }
  }
`
