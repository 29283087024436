import React from 'react'
import styled from 'styled-components'

import { config } from '../config'

const StyledInput = styled.input`
  background: transparent;
  border: none;
  border-bottom: 1px solid ${config.colors.base};
  padding: 2px 0;
  font: inherit;
  color: inherit;
  outline: none;
  border-radius: 0;
  display: block;
  width: 100%;
  max-width: 100%;
`

export function Input({ ...props }) {
  return <StyledInput type="text" {...props} />
}
